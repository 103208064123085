var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "h-screen flex w-full bg-img" }, [
    _c(
      "div",
      {
        staticClass:
          "vx-col sm:w-3/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center",
      },
      [
        _c("vx-card", [
          _c(
            "div",
            {
              staticClass: "full-page-bg-color",
              attrs: { slot: "no-body" },
              slot: "no-body",
            },
            [
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center",
                  },
                  [
                    _c("img", {
                      staticClass: "mx-auto",
                      attrs: {
                        src: require("@/assets/images/pages/reset-password.png"),
                        alt: "login",
                      },
                    }),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "p-8" },
                      [
                        _c("div", { staticClass: "vx-card__title mb-8" }, [
                          _c("h4", { staticClass: "mb-4" }, [
                            _vm._v("Alterar a senha"),
                          ]),
                          _c("p", [
                            _vm._v(
                              "Por favor preencha seu email e a nova senha com confirmação."
                            ),
                          ]),
                        ]),
                        _c("vs-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|email|min:3",
                              expression: "'required|email|min:3'",
                            },
                          ],
                          staticClass: "w-full",
                          attrs: {
                            name: "email",
                            "icon-no-border": "",
                            icon: "icon icon-user",
                            "icon-pack": "feather",
                            "label-placeholder": "E-mail",
                          },
                          model: {
                            value: _vm.email,
                            callback: function ($$v) {
                              _vm.email = $$v
                            },
                            expression: "email",
                          },
                        }),
                        _c("span", { staticClass: "text-danger text-sm" }, [
                          _vm._v(_vm._s(_vm.errors.first("email"))),
                        ]),
                        _c("vs-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|min:6",
                              expression: "'required|min:6'",
                            },
                          ],
                          ref: "password",
                          staticClass: "w-full mt-6",
                          attrs: {
                            type: "password",
                            name: "password",
                            "icon-no-border": "",
                            icon: "icon icon-lock",
                            "icon-pack": "feather",
                            "label-placeholder": "Senha",
                            "data-vv-as": "Senha",
                          },
                          model: {
                            value: _vm.password,
                            callback: function ($$v) {
                              _vm.password = $$v
                            },
                            expression: "password",
                          },
                        }),
                        _c("span", { staticClass: "text-danger text-sm" }, [
                          _vm._v(_vm._s(_vm.errors.first("password"))),
                        ]),
                        _c("vs-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|confirmed:password",
                              expression: "'required|confirmed:password'",
                            },
                          ],
                          staticClass: "w-full mt-6",
                          attrs: {
                            type: "password",
                            name: "password_confirmation",
                            "icon-no-border": "",
                            icon: "icon icon-lock",
                            "icon-pack": "feather",
                            "label-placeholder": "Repita a senha",
                            "data-vv-as": "Repita a senha",
                          },
                          model: {
                            value: _vm.password_confirmation,
                            callback: function ($$v) {
                              _vm.password_confirmation = $$v
                            },
                            expression: "password_confirmation",
                          },
                        }),
                        _c("span", { staticClass: "text-danger text-sm" }, [
                          _vm._v(
                            _vm._s(_vm.errors.first("password_confirmation"))
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex flex-wrap justify-between flex-col-reverse sm:flex-row mt-8",
                          },
                          [
                            _c(
                              "vs-button",
                              {
                                staticClass:
                                  "w-full sm:w-auto mb-8 sm:mb-auto mt-3 sm:mt-auto",
                                attrs: { type: "border", to: "/login" },
                              },
                              [_vm._v("Voltar ao Login")]
                            ),
                            _c(
                              "vs-button",
                              {
                                staticClass: "w-full sm:w-auto",
                                attrs: { disabled: !_vm.validateForm },
                                on: { click: _vm.resetPassword },
                              },
                              [_vm._v("Alterar")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "vs-alert",
                          {
                            staticClass: "mt-5",
                            attrs: {
                              active: _vm.alert,
                              "icon-pack": "feather",
                              icon: "icon-info",
                              closable: "",
                              "close-icon": "icon-x",
                            },
                            on: {
                              "update:active": function ($event) {
                                _vm.alert = $event
                              },
                            },
                          },
                          [
                            _c("span", [
                              _vm._v("Sua senha foi redefinida com sucesso! "),
                              _c("br"),
                              _vm._v(" Faça login novamente."),
                            ]),
                          ]
                        ),
                        _c(
                          "vs-alert",
                          {
                            staticClass: "mt-5",
                            attrs: {
                              active: _vm.errorAlert,
                              color: "danger",
                              "icon-pack": "feather",
                              icon: "icon-info",
                              closable: "",
                              "close-icon": "icon-x",
                            },
                            on: {
                              "update:active": function ($event) {
                                _vm.errorAlert = $event
                              },
                            },
                          },
                          [
                            _vm.simpleError
                              ? _c("p", {
                                  domProps: {
                                    innerHTML: _vm._s(_vm.simpleError),
                                  },
                                })
                              : _vm._e(),
                            _vm._l(
                              _vm.errorAlertMessage.email,
                              function (errorEmail, i) {
                                return _c("p", { key: i }, [
                                  _vm._v(_vm._s(errorEmail)),
                                ])
                              }
                            ),
                            _vm._l(
                              _vm.errorAlertMessage.password,
                              function (errorPassword, k) {
                                return _c("p", { key: k + "l" }, [
                                  _vm._v(_vm._s(errorPassword)),
                                ])
                              }
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ]
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }