<template>
    <div class="h-screen flex w-full bg-img">
        <div class="vx-col sm:w-3/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center">
            <vx-card>
                <div slot="no-body" class="full-page-bg-color">
                    <div class="vx-row">
                        <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
                            <img src="@/assets/images/pages/reset-password.png" alt="login" class="mx-auto">
                        </div>
                        <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center  d-theme-dark-bg">
                            <div class="p-8">
                                <div class="vx-card__title mb-8">
                                    <h4 class="mb-4">Alterar a senha</h4>
                                    <p>Por favor preencha seu email e a nova senha com confirmação.</p>
                                </div>

                                <vs-input
                                    v-validate="'required|email|min:3'"
                                    name="email"
                                    icon-no-border
                                    icon="icon icon-user"
                                    icon-pack="feather"
                                    label-placeholder="E-mail"
                                    v-model="email"
                                    class="w-full"/>
                                <span class="text-danger text-sm">{{ errors.first('email') }}</span>

                                <vs-input
                                    v-validate="'required|min:6'"
                                    type="password"
                                    name="password"
                                    icon-no-border
                                    icon="icon icon-lock"
                                    icon-pack="feather"
                                    label-placeholder="Senha"
                                    v-model="password"
                                    ref="password"
                                    data-vv-as="Senha"
                                    class="w-full mt-6" />
                                <span class="text-danger text-sm">{{ errors.first('password') }}</span>
                                <vs-input
                                    v-validate="'required|confirmed:password'"
                                    type="password"
                                    name="password_confirmation"
                                    icon-no-border
                                    icon="icon icon-lock"
                                    icon-pack="feather"
                                    label-placeholder="Repita a senha"
                                    v-model="password_confirmation"
                                    data-vv-as="Repita a senha"
                                    class="w-full mt-6" />
                                <span class="text-danger text-sm">{{ errors.first('password_confirmation') }}</span>
                                <div class="flex flex-wrap justify-between flex-col-reverse sm:flex-row mt-8">
                                    <vs-button type="border" to="/login" class="w-full sm:w-auto mb-8 sm:mb-auto mt-3 sm:mt-auto">Voltar ao Login</vs-button>
                                    <vs-button @click="resetPassword" :disabled="!validateForm" class="w-full sm:w-auto">Alterar</vs-button>
                                </div>
                                <vs-alert :active.sync="alert" class="mt-5" icon-pack="feather" icon="icon-info" closable close-icon="icon-x">
                                  <span>Sua senha foi redefinida com sucesso! <br> Faça login novamente.</span>
                                </vs-alert>
                                <vs-alert :active.sync="errorAlert" class="mt-5" color="danger" icon-pack="feather" icon="icon-info" closable close-icon="icon-x">
                                  <p v-if="simpleError" v-html="simpleError"></p>
                                  <p v-for="(errorEmail, i) in errorAlertMessage.email" :key="i">{{ errorEmail }}</p>
                                  <p v-for="(errorPassword, k) in errorAlertMessage.password" :key="k+'l'">{{ errorPassword }}</p>
                                </vs-alert>
                            </div>
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>
    </div>
</template>

<script>
import axios from '@/axios.js'

export default {
  data () {
    return {
      token: this.$route.query.token || null,
      email: '',
      password: '',
      password_confirmation: '',
      alert: false,
      errorAlert: false,
      errorAlertMessage: {
        email: [],
        password: []
      },
      simpleError: null
    }
  },
  computed: {
    validateForm () {
      return !this.errors.any() && this.email !== '' && this.password !== '' && this.password_confirmation !== ''
    }
  },
  methods: {
    async resetPassword () {
      this.errorAlertMessage = { email: [], password: [] }
      this.simpleError = null
      this.errorAlert = false //preciso fechar aqui para que seja renderizado e reabra no tamanho certo
      this.$vs.loading()
      try {
        const resp = await axios.post(`${process.env.VUE_APP_API_URL}/password/reset`, {
          token: this.token,
          email: this.email,
          password: this.password,
          password_confirmation: this.password_confirmation
        })
        this.errorAlert = false
        this.alert = true
        this.$vs.loading.close()
      } catch (error) {
        console.log(error.response)
        this.alert = false
        if (error.response.data.error) {
          console.log(error.response.data.error)
          if (error.response.data.error === 'Não encontramos um usuário com esse endereço de e-mail.') {
            this.simpleError = error.response.data.error
          } else {
            this.simpleError = 'O tempo para alterar a senha se esgotou ou a senha já foi alterada, por favor tente reenviar outro email clicando <a href="/forgot-password">AQUI</a>'
          }
        }

        if (error.response.data.errors) {
          this.errorAlertMessage = error.response.data.errors
        }
        this.errorAlert = true
        this.$vs.loading.close()
      }
    }
  }
}
</script>
